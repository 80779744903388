<template>
  <div>
    <v-container id="regular-tables" fluid tag="section">
      <v-simple-table :dark="$store.state.isDarkMode">
        <thead class="table-heading" v-if="!tableOptions.loading">
          <tr>
            <template v-for="(name, i) in tableOptions.tableHeaders" >
              <th   v-if="name!='settings'" >
                {{ $t(name) }}
              </th>
              <th v-else>
                <dialogTable :params="headerList" @header-update="updateHeaders($event)"/>
              </th>
            </template>
          </tr>
        </thead>
        <div class="table__spinnerWrapper" v-if="tableOptions.loading">
          <app-spinner></app-spinner>
        </div>
        <v-fade-transition mode="out-in" :group="true" tag="tbody">
          <tr
            v-for="(item, idx) in tableOptions.tableData"
            :key="idx"
            v-show="!tableOptions.loading"
          >
            <td :key="index" v-for="(value, key, index) of castItems(item)">
              <span v-html="accessData(item, index)"></span>
            </td>
            <td :class="{ 'heightWidth': serverRoute == '/test' }" class="justify-center" v-if="displaySettings">
              <slot name="editFeature" :row="item"></slot>
              <app-table-delete-modal
                v-if="enableDelete"
                :tableRecord="item"
                :serverRoute="serverRoute"
                @record-deleted="reFetchData(tableOptions.tableMeta.page)"
              ></app-table-delete-modal>
            </td>
          </tr>
        </v-fade-transition>
      </v-simple-table>
      <div class="d-flex justify-end mt-5" v-if="!tableOptions.loading">
        <app-pagination
          :totalPages="tableOptions.tableMeta.total"
          :page="tableOptions.tableMeta.page"
          :perPage="getPerPage()"
          @PaginationValue="fetchNewPaginated($event)"
        ></app-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
import spinner from "./spinner.vue";
import pagination from "./pagination.vue";
import tableDeleteModal from "./tableDeleteModal.vue";
import dialogTable from "../../views/dashboard/components/core/dialogTable.vue";
//
export default {
  components: {
    dialogTable,
    appTableDeleteModal: tableDeleteModal,
    appSpinner: spinner,
    appPagination: pagination,
  },
  data() {
    return {
      deleteDialog: false,
      headerList: {},
    };
  },

  props: {
    serverRoute: String,
    enableDelete: {
      type: Boolean,
      default: true,
    },
    flaggedItems: {
      type: Array,
      default() {
        return [];
      },
    },
    displaySettings: {
      type: Boolean,
      default: true,
    },
    tableOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  // computed: {
  //   ...mapGetters({
  //     tableOptions: "tablesStore/getTableOptions",
  //   }),
  // },
  methods: {
    updateHeaders(newHeaders) {
      this.tableOptions.loading = true;
      this.tableOptions.tableHeaders = [];
      this.tableOptions.accessTableData = [];
      this.headerList = newHeaders;
      newHeaders.text.map((header,i) => {
          if(header.value) {
            this.tableOptions.tableHeaders.push(header.name);
            if(newHeaders.value[i]) {
              this.tableOptions.accessTableData.push(newHeaders.value[i]);
              this.flaggedItems.map((el,j)=> {
                if(el[0]==newHeaders.value[i][0]) this.flaggedItems.splice(j,1);
              })
            }
          } else { 
             if(newHeaders.value[i]) this.flaggedItems.push(newHeaders.value[i]); 
          }
      });
      this.tableOptions.loading = false;
    },
    getPerPage() {
      if (this.tableOptions.tableMeta.perPage) {
        return this.tableOptions.tableMeta.perPage;
      } else {
        return 10;
      }
    },
    castItems(item) {
      let copyItem = { ...item };
      this.flaggedItems.forEach((el) => {
        delete copyItem[el];
      });
      return copyItem;
    },
    accessData(row, index) {
      return this.fetchAttributes(
        row,
        this.tableOptions.accessTableData[index]
      );
    },
    fetchAttributes(row, nestedKey) {
      let value = row;
      for (let key in nestedKey) {
        if (value[nestedKey[key]]) {
          value = value[nestedKey[key]];
        } else {
          return;
        }
      }
      return this.$i18n.t(value.toLocaleString());
    },
    fetchNewPaginated(paginationValue) {
      this.$emit("re-fetch-paginated-data", paginationValue);
    },
    reFetchData(paginationValue) {
      this.$emit("re-fetch-data", paginationValue);
    },
  },
  created(){
    this.tableOptions.loading = true;
    const text = this.tableOptions.tableHeaders.map((header) => ({
      name: header,
      value: 1, // Set default value as 1
    }));
    const value = [...this.tableOptions.accessTableData];
    console.log(value);
    this.headerList = {
      text, value,
    }
    this.tableOptions.loading = false;
  }
};
</script>

<style>
.heightWidth{
  width: 20%;
}
</style>