<template>
  <v-dialog v-model="tableDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-if="isIcon" v-on="on" v-bind="attrs" @click="createHeaders" >mdi-dots-vertical</v-icon>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center card-title__heading">
        <h2> {{ $t('') }} </h2>
      </v-card-title>
      <v-card-text>
        <template>
          <v-col class="justify-center py-5" md="12">
            <h3 style="color: var(--bg-color--font);">{{ $t('Header fields') }}</h3>
          </v-col>
          <v-row v-if="tempHeader.text.length">
            <template v-for="(item, index) in tempHeader.text">
              <v-checkbox v-if="item.name && item.name!='settings'" :dark="$store.state.isDarkMode" :key="index" :label="$t(item.name)" class="mx-4 mb-4"
                v-model="tempHeaderCopy.text[index].value" hide-details :true-value="1" :false-value="0"></v-checkbox>
            </template>
          </v-row>
        </template>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="green" class="white--text" depressed @click="tableRecord()" :loading="tableBtnLoading"> {{
          $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      tableBtnLoading: false,
      tableDialog: false,
      tempHeader: { text: [], value: [] },
      tempHeaderCopy: { text: [], value: [] },
    };
  },
  props: {
    isIcon: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
      required: true,
    },
  },
  // watch: {
  //   tableDialog() {
  //     }
  // },
  methods: {
    createHeaders() {
      this.tempHeader.text = [...this.params.text];
      this.tempHeader.value = [...this.params.value];
      this.tempHeaderCopy = JSON.parse(JSON.stringify(this.tempHeader));
    },
    tableRecord() {
      this.tempHeader = JSON.parse(JSON.stringify(this.tempHeaderCopy));
      this.$emit("header-update", this.tempHeader);
      this.tableDialog = false;
    }
  },
  created() {
  }
};
</script>

<style></style>