import axios from "axios";
const getDefaultState = () => {
  return {
    loading: false,
    student: {
      information: {
        fatherName: {
          description: "father name",
          value: "",
        },
        motherName: {
          description: "mother name",
          value: "",
        },
        grand_father_name: {
          description: "grand father name",
          value: "",
        },
        public_record_number: {
          description: "public record number",
          value: "",
        },
        gender: {
          description: "gender",
          value: "",
        },
        type: {
          description: "type",
          value: "",
        },
        transferNumber: {
          description: "transported number",
          value: "",
        },
        birth_location: {
          description: "birthday place",
          value: "",
        },
        birthday: {
          description: "birthday date",
          value: "",
        },
        transfer_document_date: {
          description: "transfer document date",
          value: "",
        },
        nationality: {
          description: "nationality",
          value: "",
        },

        // father_job: {
        //   description: "father career",
        //   value: "",
        // },

        birth_registration_id: {
          description: "Registration number",
          value: "",
        },
        join_date: {
          description: "Date of joining the school",
          value: "",
        },
        foreign_language: {
          description: "foreign language",
          value: "",
        },
        mobile_number: {
          description: "phone number",
          value: "",
        },
        phone_number: {
          description: "land phone number",
          value: "",
        },
        student_email: {
          description: "student email",
          value: "",
        },
        // parent_email: {
        //   description: "parent email",
        //   value: "",
        // },
        address: {
          description: "address",
          value: "",
        },

        // parent_passport_number: {
        //   description: "parent passport number",
        //   value: "",
        // },
        student_passport_number: {
          description: "student passport number",
          value: "",
        },
        student_contact: {
          description: "student contact",
          value: "",
        },
        term: {
          description: "term",
          value: "",
        },
        grade: {
          description: "class",
          value: "",
        },
        class: {
          description: "educitional class",
          value: "",
        },
        room: {
          description: "room",
          value: "",
        },
        floor: {
          description: "floor",
          value: "",
        },
        route: {
          description: "Route Name",
          value: "",
        },
      },
      firstName: "",
      grade: "",
      class: "",
      room: "",
      floor: "",
      register: false,
      registration_record_id: null,
      bill_type: null,
      invoice_type_id: null,
      // photos
      student_photo: null,
      minorPhotos: {
        familyBook1: {
          text: "Father Residence/ID Photo",
          value: null,
        },
        familyBook2: {
          text: "Father Passport Photo",
          value: null,
        },
        familyBook3: {
          text: "Student Residence/ID Photo",
          value: null,
        },
        familyBook4: {
          text: "Student Passport Photo",
          value: null,
        },
        // fatherPhotoId: {
        //   text: "Fathers ID photo",
        //   value: null,
        // },
        medicalReport: {
          text: "medical report",
          value: null,
        },
        studySequence: {
          text: "student study sequence",
          value: null,
        },
        vaccine_card: {
          text: "Vaccine card",
          value: null,
        },
        transfer_document_photo: {
          text: "Transfer document",
          value: null,
        },
      },
    },
    tableOptions: {
      tableHeaders: [],
      tableData: [],
      accessTableData: [],
      loading: false,
      tableMeta: {
        total: 10,
        page: 1,
      },
    },
  };
};
const state = getDefaultState();

const getters = {
  getStudentRegisterStatus(state) {
    return state.student.register;
  },
  getLoadingStatus(state) {
    return state.loading;
  },
  getStudentData(state) {
    return state.student;
  },
  getStudentRegistrationId(state) {
    return state.student.registration_record_id;
  },
  getTableOptions(state) {
    return state.tableOptions;
  },
  getTableLoading(state) {
    return state.tableOptions.loading;
  },
};
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setLoadingStatus(state, status) {
    state.loading = status;
  },
  setStudentInformation(state, studentInfo) {
    state.student.student_photo = studentInfo.student_photo;
    state.student.minorPhotos.familyBook1.value = studentInfo.family_book_1;
    state.student.minorPhotos.familyBook2.value = studentInfo.family_book_2;
    state.student.minorPhotos.familyBook3.value = studentInfo.family_book_3;
    state.student.minorPhotos.familyBook4.value = studentInfo.family_book_4;
    //state.student.minorPhotos.fatherPhotoId.value = studentInfo.father_photo_id;
    state.student.minorPhotos.medicalReport.value = studentInfo.medical_report;
    state.student.minorPhotos.studySequence.value = studentInfo.study_sequence;
    state.student.minorPhotos.vaccine_card.value = studentInfo.vaccine_card;
    if (studentInfo.type == "transported") {
      state.student.minorPhotos.transfer_document_photo.value =
        studentInfo.transfer_document_photo;
      state.student.information.transferNumber.value =
        studentInfo.number_transfer_document;
    }

    state.student.firstName = studentInfo.first_name;

    state.student.information.motherName.value = studentInfo.mother_name;
    state.student.information.public_record_number.value = studentInfo.public_record_number;
    state.student.information.fatherName.value = studentInfo.father_name;
    state.student.information.address.value = studentInfo.address;
    state.student.information.route.value = studentInfo.route;


    // state.student.information.parent_passport_number.value = studentInfo.parent_passport_number;

    state.student.information.student_contact.value = studentInfo.student_contact;

    state.student.information.student_passport_number.value = studentInfo.student_passport_number;

    state.student.information.student_email.value = studentInfo.student_email;
    // state.student.information.parent_email.value = studentInfo.parent_email;

    state.student.information.birth_location.value = studentInfo.birth_location;
    state.student.information.birth_registration_id.value = studentInfo.birth_registration_id;
    state.student.information.birthday.value = studentInfo.birthday;
    state.student.information.transfer_document_date.value = studentInfo.transfer_document_date;
    // state.student.information.father_job.value = studentInfo.father_job;
    state.student.information.foreign_language.value = studentInfo.foreign_language;
    state.student.information.grand_father_name.value = studentInfo.grand_father_name;
    state.student.information.join_date.value = studentInfo.join_date;
    state.student.information.mobile_number.value = studentInfo.mobile_number;
    state.student.information.nationality.value = studentInfo.nationality;
    state.student.information.phone_number.value = studentInfo.phone_number;
    state.student.registration_record_id = studentInfo.registration_record_id;
    state.student.invoice = studentInfo.invoice;
    state.student.bill_type = studentInfo.bill_type;
    state.student.invoice_type_id = studentInfo.invoice_type_id;
    state.student.invoice_id = studentInfo.invoice_id;
    state.student.information.gender.value = studentInfo.gender;
    state.student.information.type.value = studentInfo.type;
    state.student.information.term.value = studentInfo.term;
    // if (studentInfo.gender == "male") {
    //   state.student.information.gender.value = i18n.t('male');
    // } else {
    //   state.student.information.gender.value = i18n.t('female') ;
    // }
    // if (studentInfo.type == "transported") {
    //   state.student.information.type.value = i18n.t('Transported');
    // } else {
    //   state.student.information.type.value = i18n.t('new');
    // }
    state.student.register = studentInfo.register;
  },
  setStudentClassInfo(state, classInfo) {
    state.student.grade = classInfo.data.data.grade;
    state.student.class = classInfo.data.data;
    state.student.room = classInfo.data.data.room;
    state.student.floor = classInfo.data.data.room.floor;
    state.student.information.grade.value = classInfo.data.data.grade.name;
    state.student.information.class.value = classInfo.data.data.name;
    state.student.information.room.value = classInfo.data.data.room.name;
    state.student.information.floor.value = classInfo.data.data.room.floor.name;

  },
  setLoading(state, status) {
    state.tableOptions.loading = status;
  },
  setTableData(state, tableInfo) {
    state.tableOptions.tableHeaders = tableInfo.tableHeaders;
    state.tableOptions.tableData = tableInfo.tableData;
    state.tableOptions.accessTableData = tableInfo.accessTableData;
    state.tableOptions.tableMeta.total = tableInfo.tableMeta.total;
    state.tableOptions.tableMeta.page = tableInfo.tableMeta.page;
  },
};
const actions = {
  async fetchRegistrationRecord({ commit, state }, studentId) {
    try {
      commit("resetState");
      commit("setLoadingStatus", true);
      const response = await axios.get(`/student/${studentId}`);
      console.log("reg record fam", response);
      let responseData = response.data.data;

      commit("setStudentInformation", responseData);

      if (!state.student.register) {
        const classStudentResponse = await axios.get(
          `/registration-data/${studentId}`
        );
        commit("setStudentClassInfo", classStudentResponse);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      commit("setLoadingStatus", false);
    }
  },
  async fetchStudentNotes({ commit }, queryParams) {
    try {
      commit("setLoading", true);
      let params = {
        size: 10,
        registration_record_id: queryParams.studentRegistrationId,
      };
      if (queryParams.paginationValue) {
        params.page = queryParams.paginationValue;
      }
      const response = await axios.get("/note", {
        params,
      });
      response.data.notes.forEach((note) => {
        if (note.type == "medical") {
          note.localeType = "medical";
        } else if (note.type == "educational") {
          note.localeType = "educational";
        } else {
          note.localeType = "psychological";
        }
      });
      commit("setTableData", {
        tableHeaders: [
          "id",
          "note type",
          "note address",
          "note text",
          "date",
          "user name",
          "settings",
        ],
        tableData: response.data.notes,
        accessTableData: [
          ["id"],
          ["localeType"],
          ["title"],
          ["note"],
          ["date"],
          ["username"],
        ],
        tableMeta: {
          total: response.data.meta.total,
          page: response.data.meta.current_page,
        },
      });
    } catch (err) {
      console.log("err", err);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchAbsents({ commit }, queryParams) {
    try {
      commit("setLoading", true);
      let params = {
        size: 10,
      };
      for (let key in queryParams) {
        if (queryParams[key]) {
          params[key] = queryParams[key];
        }
      }
      const response = await axios.get("/absent/registration-record", {
        params,
      });
      response.data.data.forEach((el) => {
        if (el.type == "justified") {
          el.localeType = "justified";
        } else {
          el.localeType = "not justified";
        }
      });
      commit("setTableData", {
        tableHeaders: [
          "id",
          "day",
          "hr.vacation.note",
          "type",
          "date",
          "settings",
        ],
        tableData: response.data.data,
        accessTableData: [["id"], ["day"], ["note"], ["localeType"], ["date"]],
        tableMeta: {
          total: response.data.meta.total,
          page: response.data.meta.current_page,
        },
      });
    } catch (err) {
      console.log("err", err);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchTests({ commit }, queryParams) {
    try {
      commit("setLoading", true);
      let params = {
        size: 10,
      };
      for (let key in queryParams) {
        if (queryParams[key]) {
          params[key] = queryParams[key];
        }
      }
      const response = await axios.get("/test/registration-record", {
        params,
      });
      console.log("tests", response.data.data);
      commit("setTableData", {
        tableHeaders: [
          "id",
          "name",
          "subject",
          "Mark",
          "lower mark",
          "Maximum Mark",
          "date",
        ],
        tableData: response.data.data,
        accessTableData: [
          ["id"],
          ["name"],
          ["subject", "name"],
          ["mark", "mark"],
          ["min_mark"],
          ["max_mark"],
          ["date"],
        ],
        tableMeta: {
          total: response.data.meta.total,
          page: response.data.meta.current_page,
        },
      });
    } catch (err) {
      console.log("err", err);
    } finally {
      commit("setLoading", false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
