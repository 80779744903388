import axios from "axios";

const getDefaultState = () => {
  return {
    loading: false,
    lessons: [],
   copy_lesson:{},
    lesson: {
      homeworks: [],
      attachments: [],
      title: {
        desc: "title",
        value: "",
      },
      teacherName: {
        desc: "teacher",
        value: "",
      },
      subject: {
        desc: "subject",
        value: "",
      },
      day: {
        desc: "day",
        value: "",
      },
      order: {
        desc: "order",
        value: "",
      },
      date: {
        desc: "date",
        value: "",
      },
      created_at: {
        desc: "Created At",
        value: "",
      },
      class: {
        desc: "class",
        value: "",
      },
      edu_class: {
        desc: "educitional class",
        value: "",
      },
      description: {
        desc: "description",
        value: "",
      },
      notes: {
        desc: "notes",
        value: "",
      },
    },
    tableOptions: {
      tableHeaders: [],
      tableData: [],
      accessTableData: [],
      loading: false,
      tableMeta: {
        total: 10,
        page: 1,
      },
    },
  };
};
const state = getDefaultState();

const getters = {
  getTableOptions(state) {
    return state.tableOptions;
  },
  getCopyLesson(state){
    return state.copy_lesson;
  },
  getLessonData(state) {
    return state.lesson;
  },
  getLessonsData(state) {
    return state.lessons;
  },

  getLoadingStatus(state) {
    return state.loading;
  },

  getTableLoading(state) {
    return state.tableOptions.loading;
  },
};
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setCopyLesson(state,lesson){
     state.copy_lesson=lesson;
  },
  setLoadingStatus(state, status) {
    state.loading = status;
  },
  setLessons(state, lessonInfo) {
    state.lessons = lessonInfo;
  },
  setLessonInformation(state, lessonInfo) {
    state.lesson.homeworks = lessonInfo.homeworks;
    // state.lesson.lecture.value = lessonInfo.lecture;
    state.lesson.attachments = lessonInfo.attachments;
    state.lesson.title.value = lessonInfo.title;
    state.lesson.description.value = lessonInfo.description;
    state.lesson.notes.value = lessonInfo.notes;
    state.lesson.teacherName.value = lessonInfo.responsibility.teacher.name;
    state.lesson.subject.value = lessonInfo.responsibility.subject.name;
    state.lesson.day.value = lessonInfo.responsibility.day;
    state.lesson.order.value = lessonInfo.responsibility.order;
    state.lesson.date.value = lessonInfo.date;
    state.lesson.created_at.value = lessonInfo.created_at;
    state.lesson.class.value = lessonInfo.responsibility.grade.name;
    state.lesson.edu_class.value = lessonInfo.responsibility.edu_class.name;
  },

  setLoading(state, status) {
    state.tableOptions.loading = status;
  },
  setTableData(state, tableInfo) {
    state.tableOptions.tableHeaders = tableInfo.tableHeaders;
    state.tableOptions.tableData = tableInfo.tableData;
    state.tableOptions.accessTableData = tableInfo.accessTableData;
    state.tableOptions.tableMeta.total = tableInfo.tableMeta.total;
    state.tableOptions.tableMeta.page = tableInfo.tableMeta.page;
  },
};
const actions = {
  async fetchLessons({ commit }, payload) {
    try {
      console.log("fetchLessons");
      commit("setLoading", true);
      let params = {
        size: 10,
      };
      for (let key in payload.params) {
        if (payload.params[key]) {
          params[key] = payload.params[key];
        }
      }
      const response = await axios.get("/lesson", {
        params,
      });
      commit("setLessons", response.data.lessons);
      console.log("lessons", response);
      let tableHeaders = [];
      let accessTableData = [];
      tableHeaders = [
        "id",
        "title",

        "teacher",
        "education class",
        "subject",

        "homeworks count",
        "lecture",
        "date",
        "settings",
      ];
      accessTableData = [
        ["id"],
        ["title"],

        ["responsibility", "teacher", "name"],
        ["responsibility", "edu_class", "name"],
        ["responsibility", "subject", "name"],
        ["homeworks_count"],
        ["lecture", "title"],
        ["date"],
      ];

      commit("setTableData", {
        tableHeaders,
        tableData: response.data.lessons,
        accessTableData,
        tableMeta: {
          total: response.data.meta.total,
          page: response.data.meta.current_page,
        },
      });
    } catch (err) {
      console.log("err", err);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchLesson({ commit, state }, lessonId) {
    try {
      commit("resetState");
      commit("setLoadingStatus", true);
      const response = await axios.get(`/lesson/${lessonId}`);
      console.log("reg record faaam lesson", response);
      let responseData = response.data.data;

      commit("setLessonInformation", responseData);
      commit("setCopyLesson",responseData)
      console.log("state", state.lesson);
      console.log("CopyLesson", state.copy_lesson);
    } catch (err) {
      console.log("errrr", err);
    } finally {
      commit("setLoadingStatus", false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
